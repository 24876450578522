<template>
  <div class="signin">
    <v-container
      fill-height
      style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; margin: auto"
    >
      <v-row justify="center" style="margin: 0 auto">
        <v-container>
          <v-row>
            <v-col sm="10" md="8" offset-md="2" offset-sm="1">
              <v-form ref="form" v-model="formValid" @submit.prevent="onSubmit" :disabled="tryingLogin" lazy-validation>
                <v-card elevation="4">
                  <app-public-header :showHome="false" :showBack="false" />
                  <v-divider />
                  <v-alert v-if="authError" type="error" class="ma-4">
                    <h4>{{ authError }}</h4>
                  </v-alert>
                  <v-card-text class="px-6">
                    <h3 class="my-4">
                      {{ $t("v_login.t_sign_in_with_email_password") }}
                    </h3>
                    <v-text-field
                      tabindex="1"
                      ref="email"
                      v-model="fields.email"
                      outline
                      :label="$t('v_login.t_email')"
                      type="email"
                      name="login-email"
                      :rules="[rules.required]"
                      :disabled="tryingLogin"
                      prepend-icon="mdi-email"
                    ></v-text-field>
                    <v-text-field
                      tabindex="2"
                      ref="password"
                      v-model="fields.password"
                      :append-icon="showpw ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="showpw ? 'text' : 'password'"
                      name="login-password"
                      :label="$t('v_login.t_password')"
                      :hint="$t('v_login.t_at_least_8_characters')"
                      counter
                      @click:append="showpw = !showpw"
                      :disabled="tryingLogin"
                      prepend-icon="mdi-key"
                    ></v-text-field>
                    <template v-if="!$vuetify.breakpoint.smAndUp">
                      <v-btn
                        tabindex="3"
                        block
                        color="primary"
                        class="mt-6"
                        large
                        type="submit"
                        :disabled="tryingLogin || !formValid"
                      >
                        <v-icon v-if="!tryingLogin">mdi-lock</v-icon>
                        <span v-if="!tryingLogin" class="ml-2">{{ $t("v_login.t_sign_in") }}</span>
                        <span v-if="!tryingLogin" class="ml-1"></span>
                        <v-progress-circular v-else indeterminate />
                      </v-btn>
                      <v-btn
                        color="secondary"
                        class="mt-6"
                        outlined
                        block
                        :to="{ name: 'forgotpassword' }"
                        :disabled="tryingLogin"
                      >
                        <v-icon>mdi-refresh</v-icon>
                        <span class="ml-1">{{ $t("v_login.t_reset_password") }}</span>
                      </v-btn>
                    </template>
                  </v-card-text>
                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />
                  <v-card-actions class="pa-2 px-6 pl-4" v-if="$vuetify.breakpoint.smAndUp">
                    <v-btn color="secondary" text small :to="{ name: 'forgotpassword' }" :disabled="tryingLogin">
                      <span>{{ $t("v_login.t_reset_password") }}</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      tabindex="3"
                      color="primary"
                      class="px-8"
                      large
                      type="submit"
                      style="min-width: 200px"
                      :disabled="tryingLogin || !formValid"
                    >
                      <v-icon v-if="!tryingLogin">mdi-lock</v-icon>
                      <span v-if="!tryingLogin" class="ml-2">{{ $t("v_login.t_sign_in") }}</span>
                      <v-progress-circular v-else indeterminate />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
              <v-divider />
              <v-card class="border-top mt-2 py-1" flat v-if="$route.path !== '/help'">
                <v-card-actions class="py-0 px-6">
                  <div style="width: 150px; margin-top: -18px">
                    <TheLanguageSwitcher :small="true" :hideDetails="true" :height="20" />
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn small text plain to="/help">
                    <v-icon small class="mr-1">mdi-help-circle-outline</v-icon>
                    {{ $t("v_login.t_help") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card class="py-1 transparent" flat v-if="$platform == 'web'">
                <v-card-actions class="py-0">
                  <v-spacer></v-spacer>
                  <a target="_blank" href="https://apps.apple.com/us/app/ranes/id1612342769"
                    ><img
                      class="mx-2 apple-badge"
                      :src="
                        'https://clockworksafety-public.s3.us-west-2.amazonaws.com/app-assets/apple-badge-' +
                        ($store.state.settings.lang ? $store.state.settings.lang.toLowerCase() : 'en-us') +
                        '.svg'
                      "
                  /></a>
                  <a target="_blank" href="http://play.google.com/store/apps/details?id=com.clockworksafety.app">
                    <img
                      class="google-badge"
                      :src="
                        'https://clockworksafety-public.s3.us-west-2.amazonaws.com/app-assets/google-play-badge-' +
                        ($store.state.settings.lang ? $store.state.settings.lang.toLowerCase() : 'en-us') +
                        '.png'
                      "
                  /></a>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
    <v-footer
      class="terms-footer"
      :padless="true"
      dense
      absolute
      style="background: rgba(15, 15, 15, 0.6); z-index: 9999; height: 30px"
    >
      <v-container class="py-0">
        <v-row>
          <v-col sm="10" md="8" offset-md="2" offset-sm="1" class="text-right py-0 my-0 px-2">
            <v-btn x-small text plain href="https://ranes.com/privacy-policy/" class="mx-3">
              <span>{{ $t("v_login.t_privacy") }}</span>
            </v-btn>
            <v-btn x-small text plain href="https://ranes.com/terms-and-conditions/" class="mx-0 mr-6">
              <span>{{ $t("v_login.t_terms") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
.
<script>
import { mapActions, mapGetters } from "vuex";
import AppPublicHeader from "@/components/AppPublicHeader";
import Helpers from "@/mixins/helpers";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import TheLanguageSwitcher from "@/components/TheLanguageSwitcher";

export default {
  name: "Login",
  metaInfo() {
    return { title: this.$t("v_login.t_sign_in") };
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mixins: [Helpers],
  components: {
    AppPublicHeader,
    TheLanguageSwitcher,
  },
  data() {
    return {
      //login: {
      formValid: true,
      rules: {
        required: (v) => !!v || this.$t("t_required"),
        // Do not enforce strong email validation here, do not expose information about the password scheme to untrusted requestors
      },
      fields: {
        email: "",
        password: "",
      },
      showpw: false,
      authError: "",
      tryingLogin: false,
      message: "",
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("timeClock", ["getUserJobs", "getCategories", "getJobCostCodes", "getTimeClocks"]),
    ...mapActions("forms", ["getFormSubmissions", "getFormOpens", "getFormAssignments"]),
    ...mapActions("notifications", ["requestPushNotifications"]),

    async onSubmit() {
      this.formValid = this.$refs.form.validate();
      if (!this.formValid) {
        this.authError = this.$t("v_login.t_val_req_email_password_required");
        return;
      }
      this.tryingLogin = true;
      try {
        const r = await this.login({ email: this.fields.email, password: this.fields.password });
        if (r == "ok") {
          this.preloader();
          this.requestNotifications();
          const redirect = this.$router.history.current.query.redirect || "/";
          this.$router.push(redirect);
        } else {
          this.tryingLogin = false;
          this.authError = this.getErrorMessage(r);
          this.fields.password = "";
          this.$nextTick(() => this.$refs.password.focus());
        }
        this.tryingLogin = false;
      } catch (e) {
        this.tryingLogin = false;
        this.authError = this.getErrorMessage(e);
      } finally {
        this.tryingLogin = false;
      }
    },
    preloader() {
      let params = {};
      params.userId = this.user.userId;
      this.getUserJobs(params).then((jobs) => {
        jobs.forEach((job) => {
          this.getJobCostCodes({ jobId: job.jobId });
        });
      });
      this.getCategories();
      this.getFormSubmissions();
      this.getFormOpens();
      this.getFormAssignments();
      this.getTimeClocks();
    },
    requestNotifications() {
      if (Capacitor.isPluginAvailable("PushNotifications")) {
        this.requestPushNotifications();
      }
    },
    async getInfo() {
      await App.getInfo().then((a) => {
        this.message += a;
      });
    },
  },

  mounted() {
    this.$nextTick(() => this.$refs.email.focus());
  },
};
</script>
<style lang="scss">
.signin {
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(240, 240, 240) inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: rgb(49, 49, 49) !important;
  }
}

.theme-dark {
  .signin {
    width: 100%;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px rgb(34, 33, 33) inset !important;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: rgb(240, 240, 240) !important;
    }
  }
}

.bg-dark-trans {
  background: rgba(0, 0, 0, 0.8);
}
.google-badge {
  height: 52px;
}
</style>
